//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FacturaServices from "../../../Services/FacturaServices";

const facturaServices = new FacturaServices();
export default {
  name: "facturaModal",
  props: ["venta", "model"],
  components: {
    NuevoDatoFiscalModal: () => import("./nuevoDatoFiscalModal"),
    BuscarDatosFiscales: () => import("./buscarDatosFiscales"),
  },
  data() {
    return {
      datoFiscal: {},
      datoFiscalPublicoGeneral: JSON.parse(localStorage.getItem("datoFiscalPublicoGeneral")),
      finalizarFactura: false,
      nuevoRfcModal: false,
      datoFiscalEditar: {},
      usosCDFI: JSON.parse(localStorage.getItem("usosCfdi")),
      formasPago: JSON.parse(localStorage.getItem("formasPago")),
      metodosPagoLst: JSON.parse(localStorage.getItem("metodosPago")),
      tiposRelacion: JSON.parse(localStorage.getItem("tipoRelacion")),
      regimenFiscaleslst: JSON.parse(localStorage.getItem("regimenFiscales")),
      buscarDatoFiscal: "",
      buscarDatosFiscalesModal: false,
      usoCDFI: "",
      metodoPago: "",
      uuidRelacionado: "",
      formaPagoSeleccionado: "",
      tipoRelacion: "",
      datosFiscalesCliente: [],
      publicoGeneralSeleccionado: false,
      comentarioFactura: "",
      facturaFolio: "",
      facturaId: "",
      facturaFinalizadaModal: false,
      correo: "",
      correoModal: false,
      regimenFiscal: "",
    }
  },
  watch: {
    datoFiscal(value) {
      if(!_.isEmpty(value))
        this.cargarConfiguracionCFDI();
    }
  },
  computed: {
    metodosPago() {
      if (_.isEmpty(this.venta.tipoVenta)) return [];
      return this.metodosPagoLst.map(mp => {
        return {
          c_metodo_pago: mp.c_metodo_pago,
          descripcion: mp.descripcion,
          disable: (this.venta.tipoVenta.codigo === 100 && mp.c_metodo_pago === "PPD"),
        }
      });
    },
    regimenFiscales() {
      let lst = [];
      if (_.isEmpty(this.datoFiscal)) return [];
      if (this.datoFiscal.tipoPersona === 0)
        return _.filter(this.regimenFiscaleslst, {'fisica': "Sí"});
      else if (this.datoFiscal.tipoPersona === 1)
        return _.filter(this.regimenFiscaleslst, {'moral': "Sí"});
      else
        return lst;
    },
    datosFiscalesCompletos() {
      let validacion = !_.isEmpty(this.datoFiscal.regimenFiscalClave) || !_.isEmpty(this.datoFiscal.direccionFiscal);
      return validacion;
    }
  },
  methods: {
    isEmpty: _.isEmpty,
    onClose() {this.$emit("on-close")},
    async onshow() {
      this.loader(true);
      this.datoFiscalEditar = {};
      this.datoFiscal = "";
      this.finalizarFactura = false;
      this.buscarDatoFiscal = "";
      this.usoCDFI = "";
      this.metodoPago = "";
      this.uuidRelacionado = "";
      this.formaPagoSeleccionado = "";
      this.tipoRelacion = "";
      this.comentarioFactura = this.venta.comentariosFacturacion === "undefined" ? "" : this.venta.comentariosFacturacion;
      this.facturaFolio = "";
      this.facturaId = "";
      this.correo = "";
      this.regimenFiscal = "";
      this.publicoGeneralSeleccionado = false;
      await this.obtenerDatosFiscalesCliente();
      this.validarDatoFiscalVentaCliente();
      this.loader(false);
    },
    validarDatoFiscalVentaCliente() {
      if (!_.isEmpty(this.venta.porFacturarDatoFiscal) && this.venta.porFacturarDatoFiscal === this.datoFiscalPublicoGeneral.clienteFiscalId)
        this.datoFiscal = this.datoFiscalPublicoGeneral;
      else if (!_.isEmpty(this.venta.porFacturarDatoFiscal))
        return this.datoFiscal = _.filter(this.datosFiscalesCliente, dfc => dfc.clienteFiscalId === this.venta.porFacturarDatoFiscal)[0];
      this.$nextTick(() => this.$refs.first.focus());
    },
    async obtenerDatosFiscalesCliente() {
      let filtros = {
        clienteId: this.venta.clienteId,
        busqueda: "",
      };

      return facturaServices.buscarDatosFiscales(filtros)
        .then(datosFiscales => this.datosFiscalesCliente = datosFiscales)
        .catch(error => this.alertShow(error, "error"));
    },
    cargarRfcPublicoGeneral() {
       if (this.publicoGeneralSeleccionado) {
         this.datoFiscal = "";
         this.publicoGeneralSeleccionado = false;
       } else {
         this.datoFiscal = this.datoFiscalPublicoGeneral;
         this.publicoGeneralSeleccionado = true;
       }
    },
    cargarConfiguracionCFDI() {
      if (_.isEmpty(this.datoFiscal.direccionFiscal))
        this.alertShow("El RFC seleccionado no cuenta con régimen fiscal configurado.", "warning");
      if(_.isEmpty(this.datoFiscal.regimenFiscalClave)){
        this.regimenFiscal = "";
        this.alertShow("El RFC seleccionado no cuenta con dirección fiscal configurada.", "warning");
      } else{
        this.regimenFiscal = _.filter(this.regimenFiscales, rf => this.datoFiscal.regimenFiscalClave === rf.c_regimen_fiscal)[0];
      }
      this.usoCDFI = _.isEmpty(this.datoFiscal.usoCfdiClave) ? "" : _.filter(this.usosCDFI, u => this.datoFiscal.usoCfdiClave === u.c_uso_cfdi)[0];
      this.formaPagoSeleccionado = this.formasPago[0];
      this.metodoPago = _.filter(this.metodosPago, mp => !mp.disable)[0];
    },
    abrirModalRfc(isEdit = false) {
      if (_.isEmpty(this.datoFiscal) && isEdit) return;
      if (isEdit) this.datoFiscalEditar = _.clone(this.datoFiscal);
      else this.datoFiscalEditar = {};
      this.nuevoRfcModal = true;
    },
    async obtenerDatoFiscal(datoFiscal) {
      await this.obtenerDatosFiscalesCliente();
      this.datoFiscal = _.cloneDeep(this.datosFiscalesCliente.find((df) => df.clienteFiscalId === datoFiscal.clienteFiscalId));
      if(this.buscarDatosFiscalesModal)
        this.buscarDatosFiscalesModal = false;
    },
    confirmarFactura() {
      this.finalizarFactura = true;
      this.$nextTick(() => this.$refs.generar.$el.focus());
    },
    timbrar() {
      this.loader(true);
      let params = {
        cliente: this.venta.clienteId,
        usoCfdi: this.usoCDFI.c_uso_cfdi,
        descripcionCfdi: this.usoCDFI.descripcion,
        metodoPagoClave: this.metodoPago.c_metodo_pago,
        metodoPago: this.metodoPago.descripcion,
        formaPagoClave: this.formaPagoSeleccionado.c_forma_pago,
        formaPago: this.formaPagoSeleccionado.descripcion,
        datoFiscalId: this.datoFiscal.clienteFiscalId,
        ventas: JSON.stringify([this.venta.ventaId]),
        tipo: "I",
        tipoRelacion: !_.isEmpty(this.tipoRelacion) ? this.tipoRelacion.c_tipo_relacion : "",
        tipoRelacionDescripcion: !_.isEmpty(this.tipoRelacion) ? this.tipoRelacion.descripcion : "",
        cfdisRelacionados: !_.isEmpty(this.uuidRelacionado) ? this.uuidRelacionado : "",
        tipoFacturaPdf: 100,
        comentarioFacturacion: this.comentarioFactura || "",
        regimenFiscalClave: this.regimenFiscal.c_regimen_fiscal,
        regimenFiscalDescripcion: this.regimenFiscal.descripcion,
      };

      return facturaServices.timbrar(params)
        .then(resp => {
          let data = resp.data;
          if (data.statusTimbrado === 305)
            this.alertShow("No se pudo obtener el status del CFDI. Por favor intente timbrar el comprobante mas tarde..", "warning");

          this.facturaFolio = data.folio;
          this.facturaId = data.id;
          this.facturaFinalizadaModal = true;
          this.loader(false);
        })
        .catch(error => {
          this.loader(false);
          this.alertShow(error, "error");
        });
    },
    obtenerPdf() {
      this.loader(true);
      let filtros = {facturaId: this.facturaId};

      return facturaServices.obtenerPdf(filtros)
        .then(res => {
          let linkSource   = `data:application/pdf;base64,${res.base64}`;
          let downloadLink = document.createElement("a");
          let fileName     = res.nombre;

          downloadLink.href     = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.alertShow("PDF de factura descargado correctamente.", "exito");
        }).catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    obtenerXml() {
      this.loader(true);
      let filtros = {facturaId: this.facturaId};

      return facturaServices.obtenerXml(filtros)
        .then(res => {
          let linkSource   = `data:text/plain;base64,${res.base64}`;
          let downloadLink = document.createElement("a");
          let fileName     = res.nombre;

          downloadLink.href     = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.alertShow("XML de factura descargado correctamente.", "exito");
        }).catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    enviarCorreo() {
      this.loader(true);
      let params = {
        facturaId: this.facturaId,
        email: this.correo,
      };
      return facturaServices.enviarCorreo(params)
        .then(() => {
          this.alertShow("Se envio la factura por correo correctamente.", "exito");
          this.correoModal = false;
          this.correo = "";
        })
        .catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    limpiarFactura() {
      this.facturaFinalizadaModal = false;
      this.onClose();
    },
    abrirModalBuscarDatosFiscales(){
      this.buscarDatosFiscalesModal = true;
    },
  }
}
